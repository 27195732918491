<template>
    <div>
      <div style="text-align:center">
          <img src="@/assets/img/logo-ganbei-color-bgtransparente.png">
      </div>
      <h1 class="section-title color1">Para o cliente</h1>
      <div class="boxes-home">
        <div class="box-home box-home-1">
          <div>
            <h3>Seguro Auto</h3>
            <router-link :to="{ name: 'seguro-auto' }">
              Iniciar Cotação
            </router-link>
            
          </div>
        </div>
        <div class="box-home box-home-2">
          <div>
            <h3>Plano de Saúde individual/família (CPF)</h3>
            <router-link :to="{ name: 'seguro-saude' }">
              Iniciar Cotação
            </router-link>
          </div>
        </div>
        <div class="box-home box-home-3">
          <div>
            <h3>Plano de Saúde Empresarial (CNPJ)</h3>
            <router-link :to="{ name: 'seguro-saude-pj' }">
              Iniciar Cotação
            </router-link>
          </div>
        </div>
      </div>
      <h1 class="section-title color2">Para o corretor de seguros</h1>
      <div class="section-broker">
        <h2>Entenda como a Ganbei funciona</h2>
        <div class="section-broker-content">
          <div class="broker-box-1">
            <p>
              A fim de facilitar a conexão de seguradoras e segurados, a Ganbei desenvolveu um sistema muito eficiente para alcançar o match perfeito!
            </p>
            <a href="https://painel.ganbei.io/register">
              Quero novos clientes
            </a>
          </div>
          <div>
            <img src="@/assets/img/como-funciona-o-ganbei.jpeg">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    
  },
};
</script>
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .boxes-home {
    display: flex;
    justify-content: center;
    font-family: 'Prompt', sans-serif;
    color: #fff
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
  h1.section-title {
      font-weight: 600;
      font-family: 'Prompt', sans-serif;
      margin: 20px 0 10px;
      padding: 50px 5% 30px;
      &.color1 {
        color: #00d68a;
      }
      &.color2 {
        color: #ab59ea;
      }
  }
  .box-home {
    height: 450px;
    background-size: contain;
    border-radius: 20px;
    width: 360px;
    margin: 0 1%;
    align-items: flex-end;
    padding: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    a {
      background: #00d68a;
      padding: 10px;
      border-radius: 40px;
      margin-top: 20px;
      display: block;
      font-weight: 600;
      color: #fff;
    }
    &.box-home-1 {
      background: url(@/assets/img/bg-seguro-auto.jpg)
    }
    &.box-home-2 {
      background: url(@/assets/img/bg-seguro-saude.jpg)
    }
    &.box-home-3 {
      background: url(@/assets/img/bg-seguro-saude-empresarial.jpg)
    }
  }
  .section-broker {
    background: #6d388d;
    color: #fff;
    text-align: center;
    padding: 30px 5%;
    font-family: 'Prompt', sans-serif;
  }
  .section-broker-content {
    display: flex;
    a {
      background: #fff;
      padding: 10px;
      border-radius: 40px;
      margin-top: 20px;
      display: block;
      font-weight: 600;
      color: #6d388d !important;
    }
    .broker-box-1 {
      padding: 100px 40px 0 0;font-size: 18px;width: 700px;font-weight: 300;
    }
  }
  @media only screen and (min-width: 1450px) {
    .section-title {
      text-align: center;
    }
  }
  @media only screen and (max-width: 450px) {
    .section-title {
      text-align: center;
    }
    .boxes-home {
      display: block;
      .box-home {
        margin: 20px auto;
      }
    }
    .section-broker-content {
      display: flex;
      flex-direction: column-reverse;
      .broker-box-1 {
        width: auto;
        padding: 0 5px;
      }
    }

  }
</style>
