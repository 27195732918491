<template>
  <div class="container-full d-flex">
      <router-view />
  </div>
</template>

<script>


export default {
  
  data() {
    return {
     
    };
  },
  
  mounted() {
    
  },
  
};
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
}
</style>