import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";


const SeguroAuto = () => import("@/views/clientForm/Auto.vue")
const SeguroSaude = () => import("@/views/clientForm/Saude.vue")
const SeguroSaudePJ = () => import("@/views/clientForm/SaudePJ.vue")

//const Typeform = () => import("@/views/Typeform.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: '/cotar-seguro/auto',
    name: 'seguro-auto',
    component: SeguroAuto,
  },
  {
    path: '/cotar-seguro/saude',
    name: 'seguro-saude',
    component: SeguroSaude,
  },
  {
    path: '/cotar-seguro/saude-empresarial',
    name: 'seguro-saude-pj',
    component: SeguroSaudePJ,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



export default router;